<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader style="z-index: 99;">
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('inventoryFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square
                       size="sm" @click="openModalEvent('add', 'inventoryForm', null, 'Stok Ekleme Formu')">
                Stok Ekle
              </CButton>&nbsp;
              <CButton color="danger" square
                       size="sm" @click="openModalEvent('remove', 'inventoryForm', null, 'Stok Düşme Formu')">
                Stoktan Düş
              </CButton>&nbsp;
              <CButton color="primary" square
                       size="sm"
                       @click="getExcel"
              >
                Excel'e Aktar
              </CButton>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="inventories"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            pagination
            :loading="loading"
            hover
            @row-clicked="rowClicked"
            clickable-rows
            @row-double-clicked="showSkuBarcode(lastItem.sku.code)"
            v-on:refresh="filterInventory"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CDropdown
                  placement="right"
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="showSkuBarcode(item.sku.code)">Barkod
                    Görüntüle
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('add', 'inventoryForm', item, 'Stok Ekleme Formu')">Stok Ekle</CDropdownItem>
                  <CDropdownItem @click="openModalEvent('remove', 'inventoryForm', item, 'Stok Düşme Formu')">Stoktan Düş</CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #gender="{item}">
              <td>
                <CIcon v-if="item.sku.skuAttrCodeMap.GENDER.name === 'FEMALE'" name="cil-user-female"/>
                <CIcon v-else-if="item.sku.skuAttrCodeMap.GENDER.name === 'MALE'" name="cil-user"/>
                <span v-else> {{ item.sku.skuAttrCodeMap.GENDER.name  }}</span>
              </td>
            </template>
            <template #newFlag="{item}">
              <td>
                <CIcon v-if="item.sku.product.newFlag" class="green" name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #activeFlag="{item}">
              <td>
                <CIcon v-if="item.activeFlag" class="green"  name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #posName="{item}">
              <td> {{ item.pos.name }}</td>
            </template>
            <template #productName="{item}">
              <td> {{ item.sku.product.name }}</td>
            </template>
            <template #skuName="{item}">
              <td> {{ item.sku.name }}</td>
            </template>
            <template #color="{item}">
              <td> {{ item.sku.skuAttrCodeMap.COLOR.name }}</td>
            </template>
            <template #size="{item}">
              <td> {{ item.sku.skuAttrCodeMap.SIZE.name }}</td>
            </template>
            <template #skuCode="{item}">
              <td> {{ item.sku.code }}</td>
            </template>
            <template #skuBarcode="{item}">
              <td> {{ item.sku.barcode }}</td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <FormModal 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="inventoryCrud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <ConfirmModal
      :show="openModalConfirm" 
      :title="modalTitle" 
      :openModalEvent="openModalConfirmEvent"
      :confirm="printDiv"
      :desc="modalDesc"
      :actionType="actionType"
      :loading="loading"
      ref="confirm"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterInventory"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FilterSidebar from '../components/filterSidebar'
import ConfirmModal from '../components/confirmModal'
import FormModal from '../components/formModal'
import moment from "moment";
import { prepareFilterParams } from "@/helpers/custom"


export default {
  name: 'Inventory',
  components: {FilterSidebar, FormModal, ConfirmModal},
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'inventory',
      form: 'inventoryForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      lastItem: {},
      openSidebar: false,
      filterForm: 'inventoryFilter',
      filterParams: {},
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'Id', _style: 'font-size:12px'},
        {key: 'posName', label: 'Stok Alanı', _style: 'font-size:12px'},
        {key: 'productName', label: 'SKU_Ürün_Adı', _style: 'font-size:12px'},
        {key: 'skuName', label: 'SKU Adı', _style: 'font-size:12px'},
        {key: 'gender', label: 'Cinsiyet', _style: 'font-size:12px'},
        {key: 'color', label: 'Renk', _style: 'font-size:12px'},
        {key: 'size', label: 'Beden', _style: 'font-size:12px'},
        {key: 'amount', label: 'Adet', _style: 'font-size:12px'},
        {key: 'activeFlag', label: 'Aktif', _style: 'font-size:12px'},
        {key: 'skuCode', label: 'SKU Kodu', _style: ' white-space:nowrap;'},
        {key: 'skuBarcode', label: 'SKU Barkod', _style: 'font-size:12px'},
        {key: 'newFlag', label: 'Yeni Ürün', _style: 'font-size:12px'}
      ],
      filters: [ {
        type: 'boolean',
        dataIndex: 'activeFlag',
        field: 'activeFlag',
      }, {
        type: 'integer',
        dataIndex: 'amount',
        field: 'amount',
        comparison: 'eq'
      }, {
        type: 'list',
        dataIndex: 'posId',
        field: 'pos.id',
      },  {
        type: 'string',
        dataIndex: 'skuCode',
        field: 'sku.code'
      }, {
        type: 'string',
        dataIndex: 'skuBarcode',
        field: 'sku.barcode'
      },{
        type: 'string',
        dataIndex: 'productName',
        field: 'sku.product.name'
      },],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },

  },

  methods: {
    openModalEvent(actionType, form, data, title, desc){
      if(data){
        data.inventoryFormPos = data.pos.id;
        data.inventoryFormSKUCode = data.sku.code
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openModalConfirmEvent(actionType, title,  desc){
      this.openModalConfirm = !this.openModalConfirm
      this.actionType = actionType ? actionType : this.actionType
      this.modalDesc = desc
      this.modalTitle = title
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    rowClicked(item, index) {
      this.lastItem = item
    },

    async inventoryCrud(item, action){ 
      let params = {
        "actionType": action,
        "inventoryFormPos": item.inventoryFormPos,
        "inventoryFormSKUCode": item.inventoryFormSKUCode,
        "inventoryFormAmount": item.inventoryFormAmount,
        "inventoryFormDescription": item.inventoryFormDescription
      };
      await this.$store.dispatch('inventory_action', params);
      if(this.$store.getters.inventoryStatus.success){
        this.filterInventory(this.filterParams);
        this.openModalEvent() 
      }
    },
   
    async getExcel() {
      let params = {
        responseType: 'blob'
      }
      await this.$store.dispatch('inventory_excel', params);
      if(this.$store.getters.inventoryStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.inventoryStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Stok_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },
    
    async showSkuBarcode(skuCode) {
      const params = {
        "skuCode": skuCode
      };
      await this.$store.dispatch('inventory_showBarcode', params)
      if(this.$store.getters.inventoryStatus){
        this.openModalConfirmEvent('barcode','Barkod Yazırmak İster misin? ',this.$store.getters.inventoryStatus )
      }
    },
    async printDiv() {
      var a = window.open('', '', 'height=500, width=500');
      a.document.write(this.$refs.confirm.$refs.desc.innerHTML);
      setTimeout(() => {
          a.print() 
          a.close();
        }, 1000);
    },
    async filterInventory(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      await this.$store.dispatch('inventory_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  mounted() {
    this.filterInventory(this.filterParams);
    this.$store.dispatch('activePos_list')
  },
  computed: {
    loading: function(){
      return this.$store.getters.inventoryLoading;
    },
    inventories: function(){
      return this.$store.getters.inventory;
    }
  },
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
</style>
